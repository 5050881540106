import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer className="bg-black text-white px-[30px] py-[60px]">
      <div className="container mx-auto">
        <div className="py-[15px] flex gap-[60px]">
          <div className="grow flex -mt-[10px] justify-evenly">
            <div>
              <div className="text-[24px] leading-[52px] font-['Trenda_Bold']">
                <Link className="flex gap-[5px] items-center" to="/">
                  <img
                    src="/logo.png"
                    alt=""
                    className="h-[24px] w-auto invert"
                  />{" "}
                  Wepro®
                </Link>
              </div>
              <ul className="leading-[32px] text-[16px]">
                <li>Hakkımızda</li>
                <li>Bize Ulaşın</li>
                <li>Kariyer</li>
              </ul>
            </div>

            <div>
              <div className="text-[20px] leading-[42px] font-['Trenda_Bold']">
                Hizmetler
              </div>
              <ul className="leading-[32px] text-[16px]">
                <li>Alan Adları</li>
                <li>İnternet Sitesi</li>
                <li>Eticaret Sitesi</li>
                <li>Pazaryeri</li>
                <li>Seogum</li>
              </ul>
            </div>

            <div>
              <div className="text-[20px] leading-[42px] font-['Trenda_Bold']">
                Pazaryeri
              </div>
              <ul className="leading-[32px] text-[16px]">
                <li>Alan Adı Satışı</li>
                <li>İnternet Sitesi Satışı</li>
                <li>Uygulama Satışı</li>
              </ul>
            </div>

            <div>
              <div className="text-[20px] leading-[42px] font-['Trenda_Bold']">
                Sözleşmeler
              </div>
              <ul className="leading-[32px] text-[16px]">
                <li>Wepro Hakkında</li>
                <li>Bize Ulaşın</li>
                <li>Sözleşme ve Politikalar</li>
                <li>Kullanım Koşulları</li>
              </ul>
            </div>

            <div>
              <div className="text-[20px] leading-[42px] font-['Trenda_Bold']">
                İletişim
              </div>
              <ul className="leading-[32px] text-[16px]">
                <li>0850 000 00 00</li>
                <li>destek@wepro.com.tr</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
