// Login.js
import { Link } from "react-router-dom";
import { Button } from "../components/Button";

export const Login = () => {
  return (
    <div className="mainContainer">
      <div className="min-h-[calc(100dvh-60px)] flex items-center">
        <div className="my-[30px] shadow-lg p-[60px] mx-auto w-[600px] border-[3px] border-border rounded-[12px] flex flex-col gap-[20px]">
          <div className="text-[42px] font-['Trenda_Semibold'] flex justify-between items-center">
            Giriş yap{" "}
            <Link to="/">
              <img
                src="/logo.png"
                alt=""
                className="h-[40px] w-auto items-center"
              />
            </Link>
          </div>
          <div className="text-[18px]">
            Hesabınız yoksa,{" "}
            <Link
              to="/register"
              className="text-primary font-['Trenda_Semibold']  underline hover:no-underline"
            >
              kayıt olmak için tıklayın.
            </Link>
          </div>
          <div className="flex justify-between items-center whitespace-nowrap bg-white w-full border-[2px] border-black p-[4px]">
            <input
              type="text"
              placeholder="Eposta adresi"
              className="w-full text-[24px] px-[30px] py-[11px] outline-none"
            />
          </div>
          <div className="flex justify-between items-center whitespace-nowrap bg-white w-full border-[2px] border-black p-[4px]">
            <input
              type="password"
              placeholder="Şifre"
              className="w-full text-[24px] px-[30px] py-[11px] outline-none"
            />
          </div>
          <div className="flex flex-col gap-[15px] justify-between items-center">
            <Button
              label="Giriş Yap"
              variant="button-black"
              className="w-full h-[60px] text-[18px]"
              url="/dashboard/"
            />
            veya şununla giriş yapın:
            <div className="flex gap-[15px]">
              <Button
                variant="button-black"
                className="h-[60px] text-[18px] px-[30px]"
                url="/"
                icon={
                  <img
                    src="/icons/google.svg"
                    width={24}
                    className="invert"
                    alt=""
                  />
                }
              />
              <Button
                variant="button-black"
                className="h-[60px] text-[18px] px-[30px]"
                url="/"
                icon={
                  <img
                    src="/icons/apple.svg"
                    width={24}
                    className="invert -mt-[4px]"
                    alt=""
                  />
                }
              />
              <Button
                variant="button-black"
                className="h-[60px] text-[18px] px-[30px]"
                url="/"
                icon={
                  <img
                    src="/icons/facebook.svg"
                    width={24}
                    className="invert"
                    alt=""
                  />
                }
              />
            </div>
          </div>
          <hr className="border-border" />
          <div className="text-[18px] justify-center flex gap-[5px]">
            Şifrenizi unuttuysanız,{" "}
            <Link
              to="/login"
              className="text-primary font-['Trenda_Semibold'] underline hover:no-underline"
            >
              sıfırlamak için tıklayın.
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
