import { useMetaTags } from "react-metatags-hook";
import { Button } from "../components/Button";
import Marquee from "react-fast-marquee";

// Home.js
export const Home = () => {
  useMetaTags(
    {
      title: "Wepro",
      description: "Wepro",
    },
    []
  );

  return (
    <div>
      <div className="py-[40px] px-[30px] md:py-[60px] lg:py-[120px] relative overflow-hidden">
        <video
          loop
          autoPlay
          controls={false}
          muted
          className="absolute top-0 w-full left-0 -z-[1] opacity-[.2]"
        >
          <source src="/videos/video-1.mp4" type="video/mp4" />
        </video>
        <div className="container mx-auto">
          <div className="flex flex-col gap-[30px]">
            <div className="text-[42px] lg:text-[52px] text-center font-['Trenda_Semibold']">
              İşinizi birlikte büyütelim
            </div>
            <div className="flex gap-[30px]">
              <div className="">
                <img src="/logo.png" height={160} width={160} alt="" />
              </div>
              <div className="w-full flex flex-col gap-[15px]">
                <div className="flex justify-between items-center whitespace-nowrap bg-white w-full pr-[15px] shadow-[0px_0px_15px_-1px_rgba(0,0,0,0.2)] hover:shadow-[0px_0px_25px_-1px_rgba(0,0,0,0.2)] rounded-[4px]">
                  <input
                    type="text"
                    placeholder="İstediğiniz alan adını yazın"
                    className="w-full text-[24px] px-[30px] py-[11px] outline-none"
                  />
                  <Button
                    label="Alan Adını Ara"
                    variant="input-button"
                    url="/"
                  />
                </div>
                <span className="hidden lg:flex text-[#999] lg:text-[14px] xl:text-[16px]">
                  *com.tr alan adları vergiler dahil 129₺, com alan adları
                  vergiler dahil 399₺'den başlayan fiyatlarla hemen kayıt edin.
                </span>
              </div>
            </div>

            <div className="flex gap-[60px] flex-col md:flex-row justify-between items-center">
              <Button
                label="İnternet Siteni Hemen Oluştur"
                variant="solid-button"
                url="/"
                fullWidth
              />
              <Button
                label="Alan adı üretme aracı"
                variant="outlined-button"
                url="/"
                fullWidth
                className="hidden lg:flex"
              />
              <Button
                label="Alan Adı Transferi"
                variant="outlined-button"
                url="/"
                fullWidth
              />
            </div>
          </div>
        </div>
      </div>

      <div className="py-[60px] bg-[#ffffff] px-[30px]">
        <div className="container mx-auto">
          <div className="flex flex-col gap-[60px]">
            <div className="flex flex-col gap-[15px]">
              <div className="text-[52px] font-['Trenda_Semibold']">
                Dijital dünyaya adım atın.
              </div>
              <div className="text-[18px] text-[#666666] w-[60%]">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Pariatur quia perspiciatis, eaque dolore adipisci quibusdam
                accusamus odit soluta velit, eveniet cupiditate.
              </div>
            </div>
            <div>
              <img
                className="rounded-[12px] shadow-sm"
                src="/images/image-1.webp"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="pt-0 pb-[90px] bg-[#ffffff] px-[30px]">
        <div className="container mx-auto">
          <div className="flex flex-col gap-[60px]">
            <div className="flex flex-col gap-[15px]">
              <div className="text-[52px] font-['Trenda_Semibold']">
                Doğru alan adını seçin.
              </div>
              <div className="text-[18px] text-[#666666] w-[60%]">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Pariatur quia perspiciatis, eaque dolore adipisci quibusdam
                accusamus odit soluta velit, eveniet cupiditate.
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-[30px] mt-[60px] -mx-[30px] justify-start text-[26px]">
          <Marquee autoFill speed={100} pauseOnHover>
            <div className="mx-[15px] cursor-default px-[30px] py-[15px] bg-[#dddddd] inline-flex rounded-full hover:bg-[#999999] hover:text-white">
              leodesign.co
            </div>
            <div className="mx-[15px] cursor-default px-[30px] py-[15px] bg-[#dddddd] inline-flex rounded-full hover:bg-[#999999] hover:text-white">
              livport.com
            </div>
            <div className="mx-[15px] cursor-default px-[30px] py-[15px] bg-[#dddddd] inline-flex rounded-full hover:bg-[#999999] hover:text-white">
              thetakkas.com
            </div>
            <div className="mx-[15px] cursor-default px-[30px] pr-0 bg-[#dddddd]  inline-flex rounded-full flex justify-between items-center gap-[15px] hover:bg-[#009688] hover:text-white cursor-pointer">
              <span>wepro.com.tr</span>
              <span className="bg-black text-white text-[18px] py-[20px] px-[30px] rounded-full font-['Trenda_Semibold'] whitespace-nowrap flex gap-[5px] items-center">
                <img src="/icons/tag.svg" className="h-[20px]" alt="" />
                Teklif Ver
              </span>
            </div>
            <div className="mx-[15px] cursor-default px-[30px] py-[15px] bg-[#dddddd] inline-flex rounded-full hover:bg-[#999999] hover:text-white">
              alpeda.com
            </div>
            <div className="mx-[15px] cursor-default px-[30px] py-[15px] bg-[#dddddd] inline-flex rounded-full hover:bg-[#999999] hover:text-white">
              pakkens.com
            </div>
            <div className="mx-[15px] cursor-default px-[30px] py-[15px] bg-[#dddddd] inline-flex rounded-full hover:bg-[#999999] hover:text-white">
              cronwork.com
            </div>
            <div className="mx-[15px] cursor-default px-[30px] py-[15px] bg-[#dddddd] inline-flex rounded-full hover:bg-[#999999] hover:text-white">
              yilkarklima.com
            </div>
            <div className="mx-[15px] cursor-default px-[30px] py-[15px] bg-[#dddddd] inline-flex rounded-full hover:bg-[#999999] hover:text-white">
              dogugurdal.com
            </div>
            <div className="mx-[15px] cursor-default px-[30px] py-[15px] bg-[#dddddd] inline-flex rounded-full hover:bg-[#999999] hover:text-white">
              wantuz.com
            </div>
            <div className="mx-[15px] cursor-default px-[30px] py-[15px] bg-[#dddddd] inline-flex rounded-full hover:bg-[#999999] hover:text-white">
              seogum.com
            </div>
            <div className="mx-[15px] cursor-default px-[30px] py-[15px] bg-[#dddddd] inline-flex rounded-full hover:bg-[#999999] hover:text-white">
              cronmails.com
            </div>
          </Marquee>
        </div>
      </div>

      <div className="py-[30px] bg-[#4caf50] px-[30px]">
        <div className="container mx-auto">
          <div className="flex flex-col gap-[60px]">
            <div className="flex justify-center items-center gap-[60px]">
              <div className="text-[20px] font-['Trenda_Regular'] text-[#225125]">
                İnternet sitesinde{" "}
                <strong className="font-['Trenda_Bold']">%30 indirim</strong>{" "}
                fırsatını kaçırma! Yakında sona erecek.
              </div>
              <div>
                <Button
                  label="Şimdi bir internet sitesi oluştur"
                  variant="button-black"
                  url="/"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-[60px] bg-[#fafafa] px-[30px]">
        <div className="container mx-auto">
          <div className="flex flex-col gap-[60px]">
            <div className="flex flex-col gap-[15px]">
              <div className="text-[52px] font-['Trenda_Semibold']">
                Eticaret'e giriş yapın.
              </div>
              <div className="text-[18px] text-[#666666] w-[60%]">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Pariatur quia perspiciatis, eaque dolore adipisci quibusdam
                accusamus odit soluta velit, eveniet cupiditate.
              </div>
            </div>
            <div>
              <img
                className="rounded-[12px] shadow-sm"
                src="/images/image-1.webp"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="pt-0 pb-[90px] bg-[#fafafa] px-[30px]">
        <div className="container mx-auto">
          <div className="flex flex-col gap-[60px]">
            <div className="flex flex-col gap-[15px]">
              <div className="text-[52px] font-['Trenda_Semibold']">
                50'nin üzerinde tasarım örneği.
              </div>
              <div className="text-[18px] text-[#666666] w-[60%]">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Pariatur quia perspiciatis, eaque dolore adipisci quibusdam
                accusamus odit soluta velit, eveniet cupiditate.
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-[30px] mt-[30px] -mb-[30px] -mx-[30px] justify-start flex-col text-[26px]">
          <Marquee autoFill speed={30}>
            <div className="mx-[30px] my-[30px]">
              <img
                src="/images/t-4.webp"
                className="h-[300px] shadow-lg"
                alt=""
              />
            </div>
            <div className="mx-[30px] my-[30px]">
              <img
                src="/images/t-1.webp"
                className="h-[300px] shadow-lg"
                alt=""
              />
            </div>
            <div className="mx-[30px] my-[30px]">
              <img
                src="/images/t-3.webp"
                className="h-[300px] shadow-lg"
                alt=""
              />
            </div>
            <div className="mx-[30px] my-[30px]">
              <img
                src="/images/t-2.webp"
                className="h-[300px] shadow-lg"
                alt=""
              />
            </div>
          </Marquee>
          <div className="flex justify-center">
            <Button
              label="Tüm şablonları gör"
              variant="input-button"
              className="w-[300px]"
              url="/"
            />
          </div>
        </div>
      </div>

      <div className="py-[60px] bg-[#ffffff] px-[30px]">
        <div className="container mx-auto">
          <div className="flex flex-col gap-[60px]">
            <div className="flex flex-col text-center gap-[15px]">
              <div className="text-[52px] font-['Trenda_Semibold']">
                Karmaşık işlerle uğraşmayın.
              </div>
              <div className="text-[18px] text-[#666666] w-[60%] mx-auto">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Pariatur quia perspiciatis, eaque dolore adipisci quibusdam
                accusamus odit soluta velit, eveniet cupiditate.
              </div>
            </div>
            <div className="flex gap-[60px] flex-col md:flex-row justify-between items-center w-[60%] mx-auto">
              <Button
                label="İnternet Siteni Hemen Oluştur"
                variant="solid-button"
                url="/"
                fullWidth
              />
              <Button
                label="Alan adını kayıt et"
                variant="outlined-button"
                url="/"
                fullWidth
                className="hidden lg:flex"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
