import { Link } from "react-router-dom";
import { Button } from "./Button";

// Header.js
interface HeaderProps {
  variant?: string;
  logged?: boolean;
}

export const Header = (props: HeaderProps) => {
  const minimal = props.variant === "minimal";
  const user = props.logged || false;

  return (
    <header className="bg-black text-white px-[30px]">
      <div className="container mx-auto">
        <div className="py-[10px] flex justify-between items-center h-[60px]">
          <div className="text-[18px] flex gap-[10px] items-center font-['Trenda_Semibold']">
            <Link className="flex gap-[5px] items-center" to="/">
              <img src="/logo.png" alt="" className="h-[24px] w-auto invert" />{" "}
              Wepro®
            </Link>
            {!minimal && (
              <>
                <Link
                  className="py-[5px] rounded-[4px] px-[15px] hover:bg-[#333]"
                  to="/domain"
                >
                  Alan Adı
                </Link>
                <Link
                  className="py-[5px] rounded-[4px] px-[15px] hover:bg-[#333]"
                  to="/website"
                >
                  İnternet Sitesi
                </Link>
                <Link
                  className="py-[5px] rounded-[4px] px-[15px] hover:bg-[#333]"
                  to="/ecommerce"
                >
                  Eticaret Sitesi
                </Link>
                <Link
                  className="py-[5px] rounded-[4px] px-[15px] hover:bg-[#333]"
                  to="/marketplace"
                >
                  Pazaryeri
                </Link>
                <Link
                  className="flex items-center py-[5px] rounded-[4px] px-[15px] hover:bg-[#1d82ff]"
                  to="https://seogum.com"
                  target="_blank"
                >
                  <img
                    src="https://www.seogum.com/admin/uploads/site/logo.png"
                    alt=""
                    className="h-[26px]"
                  />
                  ®
                </Link>
              </>
            )}
          </div>
          {!minimal && (
            <>
              {user ? (
                <div className="flex group relative ">
                  <Button
                    url="/login"
                    variant="button-white"
                    className="flex-row-reverse items-center"
                    icon={<img src="/icons/caret-down.svg" width={24} alt="" />}
                    label="Doğu"
                  />
                  <div className="hidden group-hover:flex shadow-lg rounded-[4px] rounded-tr-none z-[1] flex-col absolute top-[40px] bg-white text-black w-[240px] whitespace-nowrap right-0 gap-[10px] p-[15px]">
                    <Link
                      className="py-[10px] rounded-[4px] px-[15px] hover:bg-[#eee] flex justify-between items-center"
                      to="/dashboard/domain"
                    >
                      Alan Adlarım
                      <span className="bg-border text-white font-['Trenda_Bold'] p-[0px_8px] rounded-full text-[12px]">
                        28
                      </span>
                    </Link>
                    <Link
                      className="py-[10px] rounded-[4px] px-[15px] hover:bg-[#eee] flex justify-between items-center"
                      to="/dashboard/website"
                    >
                      İnternet Sitelerim
                      <span className="bg-border text-white font-['Trenda_Bold'] p-[0px_8px] rounded-full text-[12px]">
                        2
                      </span>
                    </Link>
                    <Link
                      className="py-[10px] rounded-[4px] px-[15px] hover:bg-[#eee] flex justify-between items-center"
                      to="/dashboard/ecommerce"
                    >
                      Eticaret Sitelerim
                    </Link>
                    <Link
                      className="py-[10px] rounded-[4px] px-[15px] hover:bg-[#eee] flex justify-between items-center"
                      to="/marketplace"
                    >
                      Pazaryeri İlanlarım
                      <span className="bg-border text-white font-['Trenda_Bold'] p-[0px_8px] rounded-full text-[12px]">
                        8
                      </span>
                    </Link>
                    <hr className="border-border" />
                    <Link
                      className="py-[10px] rounded-[4px] px-[15px] hover:bg-[#eee]"
                      to="/"
                    >
                      Hesap Ayarları
                    </Link>
                    <Link
                      className="py-[10px] rounded-[4px] px-[15px] hover:bg-[#eee]"
                      to="/"
                    >
                      Ödeme Ayarları
                    </Link>
                    <Link
                      className="py-[10px] rounded-[4px] px-[15px] hover:bg-[#eee] flex justify-between items-center font-['Trenda_Bold']"
                      to="/"
                    >
                      Destek Talepleri
                      <span className="bg-black text-white font-['Trenda_Bold'] p-[0px_8px] rounded-full text-[12px]">
                        1
                      </span>
                    </Link>
                    <hr className="border-border" />
                    <Link
                      className="py-[10px] rounded-[4px] px-[15px] hover:bg-[#eee]"
                      onClick={() => (document.location.href = "/")}
                      to="/"
                    >
                      Çıkış
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="flex">
                  <Button
                    url="/login"
                    variant="button-white"
                    label="Giriş Yap"
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </header>
  );
};
