import { useMetaTags } from "react-metatags-hook";
import { Button } from "../../components/Button";
import Marquee from "react-fast-marquee";

// Websites.js
export const Websites = () => {
  useMetaTags(
    {
      title: "Wepro",
      description: "Wepro",
    },
    []
  );

  return (
    <div>
      <div className="py-[60px] bg-[#ffffff] px-[30px]">
        <div className="container mx-auto">
          <div className="flex flex-col gap-[60px]">
            <div className="flex flex-col gap-[15px]">
              <div className="text-[52px] flex justify-between items-center">
                <div className="flex gap-[60px] flex-col w-full">
                  <div>
                    <div className="text-[52px] font-['Trenda_Semibold']">
                      İnternet siteleriniz
                    </div>
                    <div className="text-[18px] text-[#666666] w-[60%]">
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Pariatur quia perspiciatis, eaque dolore adipisci
                      quibusdam accusamus odit soluta velit, eveniet cupiditate.
                    </div>
                  </div>
                  <div className="text-[18px] text-[#666666] flex justify-between w-full items-center">
                    <div className="flex gap-[30px] flex-col md:flex-row justify-between items-center">
                      <Button
                        label="Yeni Alan Adı Kayıt"
                        variant="button-black"
                        url="/"
                        fullWidth
                      />
                      <Button
                        label="Alan Adı Transferi"
                        variant="button-black"
                        url="/"
                        fullWidth
                      />
                      <Button
                        label="Başka Firmadan Portföy'e Alan Adı Ekle"
                        variant="button-black-outlined"
                        url="/"
                        fullWidth
                      />
                    </div>
                    <div className="inline-flex justify-between items-center whitespace-nowrap bg-white pl-[15px] pr-[4px] border border-border rounded-[4px] py-[4px]">
                      <input
                        type="text"
                        placeholder="Alan adını yazın"
                        className="w-[240px] text-[18px] px-[0] outline-none"
                      />
                      <Button
                        label="Ara"
                        variant="button-black"
                        className="py-[3px]"
                        url="/"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-[30px]">
                <table className="table-auto w-full text-left rounded-[4px] text-[18px] overflow-hidden shadow-lg">
                  <thead>
                    <tr className="bg-black text-white font-['Trenda_Bold'] text-[20px]">
                      <th className="py-[15px] px-[30px]">Alan adı</th>
                      <th className="py-[15px] px-[30px]">
                        <div className="flex gap-[5px]">
                          Kayit Firması
                          <img
                            src="/icons/caret-down.svg"
                            className="w-[32px] invert cursor-pointer"
                            alt=""
                          />
                        </div>
                      </th>
                      <th className="py-[15px] px-[30px]">
                        <div className="flex gap-[5px]">
                          Geçerlilik tarihi
                          <img
                            src="/icons/caret-down.svg"
                            className="w-[32px] invert cursor-pointer"
                            alt=""
                          />
                        </div>
                      </th>
                      <th className="py-[15px] px-[30px]"></th>
                    </tr>
                  </thead>
                  <tbody className="rounded-b-[12px]">
                    <tr className="border-b border-border hover:bg-[#fffddd]">
                      <td className="p-[30px]">
                        <div className="flex flex-col gap-[10px]">
                          cronwork.com
                          <div className="flex gap-[0px] text-[14px] items-center">
                            Süresinin dolmasına 32 gün kaldı.
                          </div>
                        </div>
                      </td>
                      <td className="p-[30px]">Wepro</td>
                      <td className="p-[30px]">20.12.2026</td>
                      <td className="px-[30px]">
                        <div className="flex gap-[30px] justify-end">
                          <Button
                            variant="link"
                            label="Süresini uzat"
                            icon={
                              <img src="/icons/details.svg" width={24} alt="" />
                            }
                            url="/"
                          />
                          <Button
                            variant="button-black"
                            label="Yönet"
                            url="/"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-border bg-[#fafafa] text-[#999]">
                      <td className="p-[30px]">dogugurdal.com</td>
                      <td className="p-[30px]">GoDaddy</td>
                      <td className="p-[30px]">03.08.2026</td>
                      <td className="px-[30px]">
                        <div className="flex gap-[30px] justify-end">
                          <Button
                            variant="link"
                            label="Wepro'ya transfer et"
                            icon={
                              <img
                                src="/icons/transfer.svg"
                                width={24}
                                alt=""
                              />
                            }
                            url="/"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-border hover:bg-[#fffddd]">
                      <td className="p-[30px]">
                        <div className="flex flex-col gap-[10px]">
                          <div className="flex gap-[5px]">
                            <img
                              src="/icons/danger-triangle.svg"
                              className="w-[18px]"
                              alt=""
                            />
                            atolyekafakafayasanat.com
                          </div>
                          <div className="flex gap-[0px] text-[14px] text-[#f44336] items-center">
                            Süresinin dolmasına 2 gün kaldı.
                          </div>
                        </div>
                      </td>
                      <td className="p-[30px]">Wepro</td>
                      <td className="p-[30px]">
                        <div className="text-[#f44336]">18.08.2024</div>
                      </td>
                      <td className="px-[30px]">
                        <div className="flex gap-[30px] justify-end">
                          <Button
                            variant="link"
                            label="Süresini uzat"
                            icon={
                              <img src="/icons/details.svg" width={24} alt="" />
                            }
                            url="/"
                          />
                          <Button
                            variant="button-black"
                            label="Yönet"
                            url="/"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-border hover:bg-[#fffddd]">
                      <td className="p-[30px]">cronmails.com</td>
                      <td className="p-[30px]">Wepro</td>
                      <td className="p-[30px]">07.05.2025</td>
                      <td className="px-[30px]">
                        <div className="flex gap-[30px] justify-end">
                          <Button
                            variant="link"
                            label="Süresini uzat"
                            icon={
                              <img src="/icons/details.svg" width={24} alt="" />
                            }
                            url="/"
                          />
                          <Button
                            variant="button-black"
                            label="Yönet"
                            url="/"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-border bg-[#fafafa] text-[#999]">
                      <td className="p-[30px]">rgtech.com.tr</td>
                      <td className="p-[30px]">İsimtescil</td>
                      <td className="p-[30px]">03.08.2026</td>
                      <td className="px-[30px]">
                        <div className="flex gap-[30px] justify-end">
                          <Button
                            variant="link"
                            label="Wepro'ya transfer et"
                            icon={
                              <img
                                src="/icons/transfer.svg"
                                width={24}
                                alt=""
                              />
                            }
                            url="/"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-border bg-[#fafafa] text-[#999]">
                      <td className="p-[30px]">seogum.com</td>
                      <td className="p-[30px]">Metunic</td>
                      <td className="p-[30px]">03.08.2026</td>
                      <td className="px-[30px]">
                        <div className="flex gap-[30px] justify-end">
                          <Button
                            variant="link"
                            label="Wepro'ya transfer et"
                            icon={
                              <img
                                src="/icons/transfer.svg"
                                width={24}
                                alt=""
                              />
                            }
                            url="/"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="border-b border-border hover:bg-[#fffddd]">
                      <td className="p-[30px]">usbsepeti.com</td>
                      <td className="p-[30px]">Wepro</td>
                      <td className="p-[30px]">20.12.2026</td>
                      <td className="px-[30px]">
                        <div className="flex gap-[30px] justify-end">
                          <Button
                            variant="link"
                            label="Süresini uzat"
                            icon={
                              <img src="/icons/details.svg" width={24} alt="" />
                            }
                            url="/"
                          />
                          <Button
                            variant="button-black"
                            label="Yönet"
                            url="/"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="flex gap-[30px] justify-center items-center">
                  <Button
                    variant="link"
                    label="1"
                    className="!no-underline font-['Trenda_Bold'] text-[24px]"
                    url="/dashboard"
                  />

                  <Button variant="link" label="2" url="/dashboard" />
                  <Button variant="link" label="3" url="/dashboard" />
                  <Button variant="link" label="4" url="/dashboard" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-0 pb-[90px] bg-[#ffffff] px-[30px]">
        <div className="container mx-auto">
          <div className="flex flex-col gap-[60px]">
            <div className="flex flex-col gap-[15px]">
              <div className="text-[52px] font-['Trenda_Semibold']">
                Pazaryeri
              </div>
              <div className="text-[18px] text-[#666666] w-[60%]">
                <span className="font-['Trenda_Bold']">24</span> yeni alan adı
                bu hafta pazaryerine eklendi. Toplamda{" "}
                <span className="font-['Trenda_Bold']">248</span> ilan
                yayınlanmaktadır.
                <br /> Bu hafta en çok ilgi gören kategori{" "}
                <span className="font-['Trenda_Bold']">finans</span> olurken, en
                yüksek verilen teklif ise{" "}
                <span className="font-['Trenda_Bold']">$10.000</span> oldu.
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-[30px] mt-[60px] -mx-[30px] justify-start text-[26px]">
          <Marquee autoFill speed={30}>
            <div className="mx-[30px] my-[30px]">
              <img
                src="/images/t-4.webp"
                className="h-[300px] shadow-lg"
                alt=""
              />
            </div>
            <div className="mx-[30px] my-[30px]">
              <img
                src="/images/t-1.webp"
                className="h-[300px] shadow-lg"
                alt=""
              />
            </div>
            <div className="mx-[30px] my-[30px]">
              <img
                src="/images/t-3.webp"
                className="h-[300px] shadow-lg"
                alt=""
              />
            </div>
            <div className="mx-[30px] my-[30px]">
              <img
                src="/images/t-2.webp"
                className="h-[300px] shadow-lg"
                alt=""
              />
            </div>
          </Marquee>
          <div className="flex justify-center">
            <Button
              label="Tüm ilanları gör"
              variant="input-button"
              className="w-[300px]"
              url="/"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
