import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

// Button.js
interface ButtonProps {
  label?: string;
  variant: string;
  url: string;
  fullWidth?: boolean;
  className?: string;
  icon?: ReactNode;
}

export const Button = (props: ButtonProps) => {
  const variantClass: any = {
    "input-button":
      "!rounded-[4px] px-[30px] my-[15px] h-[60px] !text-[20px] !font-['Trenda_Bold'] text-white bg-[#607d8b] hover:bg-[#445a64]",
    "solid-button-green":
      "rounded-[50px] px-[30px] my-[15px] h-[60px] !text-[20px] !font-['Trenda_Bold'] text-white border-[3px] border-[#307233] bg-[#307233] hover:border-white hover:bg-transparent",
    "solid-button":
      "rounded-[50px] px-[30px] my-[15px] h-[60px] !text-[20px] !font-['Trenda_Bold'] text-white border-[3px] border-black bg-black hover:bg-transparent hover:text-black",
    "outlined-button-green":
      "rounded-[50px] px-[30px] my-[15px] h-[60px] !text-[20px] !font-['Trenda_Bold'] text-white border-[3px] hover:border-[#307233] hover:bg-[#307233] border-white bg-transparent",
    "outlined-button":
      "rounded-[50px] px-[30px] my-[15px] h-[60px] !text-[20px] !font-['Trenda_Bold'] text-black border-[3px] border-black bg-transparent hover:bg-black hover:text-white",
    "button-black":
      "rounded-[4px] border-[2px] border-black bg-black text-white hover:bg-transparent hover:text-black transition-all",
    "button-black-outlined":
      "rounded-[4px] bg-transparent border-[2px] text-black border-black text-black hover:bg-black hover:text-white transition-all",
    "button-white":
      "rounded-[4px] border-[2px] border-white bg-white text-black",
    link: "text-black !px-0 !py-0 hover:underline",
  };
  return (
    <Link
      to={props.url}
      className={twMerge(
        "text-[16px] flex gap-[5px] items-center transition-all px-[12px] py-[8px] font-['Trenda_Semibold'] justify-center items-center whitespace-nowrap",
        props.className,
        props.fullWidth && "w-auto flex-grow",
        variantClass[props.variant]
      )}
    >
      {props.icon}
      {props.label}
    </Link>
  );
};
