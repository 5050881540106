// App.js
import { HashRouter, Route, Routes } from "react-router-dom";
import { Layout } from "./components/Layout";
import { Home } from "./pages/Home";
import { Page } from "./components/Page";
import { NotFound } from "./components/NotFound";
import { Login } from "./pages/Login";
import { Register } from "./pages/Register";
import { Dashboard } from "./components/Dashboard";
import { Domains } from "./pages/dashboard/Domains";
import { Websites } from "./pages/dashboard/Websites";
import { Ecommerces } from "./pages/dashboard/Ecommerces";

function App() {
  return (
    <HashRouter basename="/">
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/hakkimizda" element={<Home />} />
          <Route path="/iletisim" element={<Home />} />
          <Route
            path="/blockchain-cozumleri"
            element={<Page type="blockchain çözümleri" color="black" />}
          />
          <Route path="/dashboard/" element={<Dashboard />}>
            <Route index element={<Domains />} />
            <Route path="/dashboard/domain" element={<Domains />} />
            <Route path="/dashboard/website" element={<Websites />} />
            <Route path="/dashboard/ecommerce" element={<Ecommerces />} />
          </Route>
          <Route path="/marketplace" element={<Home />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
